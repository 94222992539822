@font-face {
  font-family: "Dubai";
  src: local("Dubai"), url("./fonts/Dubai-Regular.ttf") format("truetype");
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: Dubai, seri !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navBarGrid {
  display: grid;
  grid-template-columns: 30% 70%;
  width: 100%;
}
.navBarGrid-item {
  text-align: center;
}
.navLinkText {
  color: #003b6f !important;
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}


.navbar {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.navLinkText:hover {
  background-color: #003b6f;
  color: white !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #003b6f !important;
  padding-right: 1.5rem !important;
  padding-left: 0.5rem !important;
  font-size: 14px;
}

.navbar-light .navbar-nav .nav-link:hover {
  background-color: #003b6f;
  color: white !important;
}
.text-block {
  position: absolute;
  bottom: 509px;
  /* right: 20px; */
  /* background-color: #4a4646; */
  /* color: #544c4c; */
  padding-left: 80px;
  padding-right: 20px;
  top: 195px;
  opacity: 73%;

  background: transparent linear-gradient(180deg, #ecf0f5 0%, #fafbfd 20%) 0% 0%
    no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  /* border-radius: 9px; */
  width: 100%;
  padding-top: 10px;
}
.text-blocktext {
  color: #003b6f;
  font-size: 40px;
  text-align: start;
}
.text-blocktextfont13 {
  color: #003b6f;
  font-size: 13px;
  text-align: start;
  font-weight: 600;
}

.bgimage {
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url("./Images/LandingpageBG.png");
}

.title {
  height: 90px;
  margin: auto;
  margin-top: 90px;
  margin-left: 250px;
  margin-right: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.example {
  position: relative;
  background: url("./Images/LandingpageBG.png") no-repeat center center scroll;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.example::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-clip-path: inset(129px 0px 129px 0px);
  clip-path: inset(97px 0px 102px 0px);
  filter: blur(80px);
  background: url("./Images/LandingpageBG.png") no-repeat center center scroll;
  opacity: 0.56;
}
.example::after {
  position: relative;
  background: url("./Images/LandingpageBG.png") no-repeat center center scroll;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.example p {
  font-size: 2.5rem;
  position: relative;
  z-index: 1;
  padding-left: 12px;
}
.landingPageHeight {
  height: 48vh;
}
.aboutUsImagePageHeight {
  height: 48vh;
}
.ourTeamImagePageHeight {
  height: 20vh;
}
.font18 {
  font-size: 18px !important;
}
/* .effect2 {
  position: relative;
}
.effect2:before,
.effect2:after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  -webkit-box-shadow: 0 15px 10px #777;
  -moz-box-shadow: 0 15px 10px #777;
  box-shadow: 0 15px 10px #777;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
.effect2:after {
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
  right: 10px;
  left: auto;
} */

.aboutUsImage {
  position: relative;
  background: url("./Images/aboutus.png") no-repeat center center scroll;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.aboutUsImage::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-clip-path: inset(129px 0px 129px 0px);
  clip-path: inset(97px 0px 102px 0px);
  filter: blur(80px);
  background: url("./Images/aboutus.png") no-repeat center center scroll;
  opacity: 78%;
}
.aboutUsImage::after {
  position: relative;
  background: url("./Images/aboutus.png") no-repeat center center scroll;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.aboutUsImage p {
  font-size: 1.7rem;
  position: relative;
  z-index: 1;
  padding-left: 12px;
}
.aboutUsImagesubtext {
  font-size: 15px !important;
}

.abcontentGrid {
  display: grid;
  grid-template-columns: 50% 50%;
  position: relative;
  z-index: 1;
  padding: 0px 20px;
  background: rgb(4 4 4 / 60%);
  align-items: center;
  color: #ffffff;
  border-radius: 25px;
  height: 500px;
  margin-top: 50px;
}
.abcontentGridItem1 {
  color: #ffffff;
  font-size: 29px;
  /* display: flex; */
  /* justify-content: center; */
  padding: 100px 53px;
  text-align: center;
}
.abcontentGridItem2 {
  align-self: flex-end;
  text-align: center;
}
.textAlignCenter {
  text-align: center !important;
}
.font22 {
  font-size: 22px !important;
}

.AboutusTextH {
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;
  opacity: 1;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  padding: 20px;
}
.sasImageSize {
  height: 300px;
}
.comingsoonDiv {
  /* position: relative; */
  width: 100%;
  height: 100vh;

  background: url("../src/Images/Comingsoon.jpg") no-repeat;
  background-size: cover;
}
.ourTeamGrid {
  display: grid;
  grid-template-columns: 25% 75%;
  padding: 21px;
  align-items: center;
  background-color: #f1f1f1;
}
.ourTeamGridItem {
  text-align: center;
  /* align-self: center; */
  /* background: #122254; */
}
.ourTeamGridItem2 {
  /* border-right: 2px solid #487bbb; */
  text-align: center;
  align-self: center;
}
.nameText {
  /* font-size: 18px;
  padding: 12px;
  color: #d3a210;
  font-weight: 500; */

  font-size: 20px;
  padding: 12px;
  color: #0e87b5;
  font-weight: 500;
}
.designationsubText {
  color: #0db0e1;
  font-size: 17px;
  padding: 2px;
}
.directorImage {
  border-radius: 50%;
  border: 4px solid #d8ad2c;
  height: 250px;
}
.memberImage {
  border-radius: 50%;
  width: 140px;
  height: 140px;
  border: 4px solid #d8ad2c;
}
.memberColMargin {
  padding-top: 20px;
}
.justifyContentCenter {
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 1920px) {
  .navBarGrid {
    grid-template-columns: 40% 60%;
  }
  .memberColMargin {
    padding-top: 75px !important;
  }
  .example::before {
    clip-path: inset(97px 0px 102px 0px) !important;
  }
  .example p {
    font-size: 2.5rem !important;
  }

  .aboutUsImage p {
    font-size: 2.5rem;
  }
  .aboutUsImagesubtext {
    font-size: 18px !important;
  }
  .aboutUsImage::before {
    clip-path: inset(97px 0px 102px 0px) !important;
  }

  .aboutUsImagePageHeight {
    height: 50vh;
  }
  .abcontentGridItem1 {
    color: #ffffff;
    font-size: 29px;
    /* display: flex; */
    /* justify-content: center; */
    padding: 100px 53px;
    text-align: center;
  }
}

@media only screen and (min-width: 1200px) {
  .example::before {
    clip-path: inset(40px 0px 64px 0px);
  }
  .example p {
    font-size: 3rem;
  }
  .aboutUsImage::before {
    clip-path: inset(40px 0px 64px 0px);
  }
  .topnavbar {
    width: 35%;
  }
}
@media only screen and (max-width: 768px) {
  .comingsoonDiv {
    background: url("../src/Images/Comingsoonmob.jpg") no-repeat;
    background-size: cover;
  }
  .topnavbar {
    width: 80%;
  }
  .font22 {
    font-size: 15px !important;
  }
  .AboutusTextH {
    font-size: 18px;
  }
  .text-blocktext {
    font-size: 17px;
  }
  .text-block {
    bottom: 371px !important;
    right: 0px !important;
    padding-left: 15px !important;
    padding-right: 20px;
    top: 98px !important;
  }
  .text-blocktextfont13 {
    font-size: 10px !important;
  }
  .navBarGrid {
    grid-template-columns: 80% 20% !important;
  }
  .landingPageHeight {
    height: 27vh;
  }
  .aboutUsImagePageHeight {
    height: 28vh;
  }
  .example p {
    font-size: 17px !important;
    line-height: 18px;
    padding-left: 6px;
  }
  .example::before {
    background: url("./Images/ladingpagebgmobile.jpg") no-repeat center center
      scroll;
    clip-path: inset(45px 0px 60px 0px);
  }

  .example {
    background: url("./Images/ladingpagebgmobile.jpg") no-repeat center center
      scroll !important;
  }
  .example::after {
    background: url("./Images/ladingpagebgmobile.jpg") no-repeat center center !important;
  }

  .aboutUsImage p {
    padding-left: 6px;
    font-size: 18px;
  }
  .aboutUsImagesubtext {
    font-size: 13px !important;
  }
  .aboutUsImage {
    background: url("./Images/aboutusmob.png") no-repeat center center scroll !important;
  }
  .aboutUsImage::after {
    background: url("./Images/aboutusmob.png") no-repeat center center !important;
  }
  .aboutUsImage::before {
    background: url("./Images/aboutusmob.png") no-repeat center center scroll;
    clip-path: inset(35px 0px 52px 0px);
  }
  .hideMobile {
    display: none !important;
  }
  .abcontentGridItem1 {
    color: #ffffff;
    font-size: 18px;
    /* display: flex; */
    /* justify-content: center; */
    padding: 10px;
    text-align: center;
  }
  .abcontentGrid {
    grid-template-columns: 100%;
    height: fit-content;
    padding: 10px;
  }
  .ourTeamGrid {
    grid-template-columns: 100%;
  }
}
@media only screen and (min-width: 1024px) {
  .hideDesktop {
    display: none !important;
  }
  .abcontentGrid {
    display: grid;
    grid-template-columns: 38% 62%;
    /* position: relative;
    z-index: 1;
    padding: 0px 20px;
    background: rgb(4 4 4 / 60%);
    align-items: center;
    color: #ffffff;
    border-radius: 25px;
    height: 500px;
    margin-top: 50px; */
  }
  .abcontentGridItem1 {
    padding: unset;
    font-size: 25px;
    /* text-align: left; */
  }
  .abcontentGridItem2 {
    align-self: unset;
  }
  .divpanel3Card {
    height: fit-content;
  }
  .AboutusTextH {
    font-size: 21px;
  }
  .divPanel3SubText {
    font-size: 17px;
  }
}
@media only screen and (max-width: 1024px) {
  .sasImageSize {
    height: 170px;
  }

  .example::before {
    background: url("../src/Images/LandingpageBG.png") no-repeat center center
      scroll;
    clip-path: inset(45px 0px 60px 0px);
  }

  .example {
    background: url("../src/Images/LandingpageBG.png") no-repeat center center
      scroll !important;
  }
  .example::after {
    background: url("../src/Images/LandingpageBG.png") no-repeat center center !important;
  }

  .example::before {
    background: url("../src/Images/LandingpageBG.png") no-repeat center center
      scroll;
    clip-path: inset(45px 0px 60px 0px);
  }

  .aboutUsImage::before {
    background: url("../src/Images/aboutus.png") no-repeat center center scroll;
    clip-path: inset(45px 0px 60px 0px);
  }
  .aboutUsImage {
    background: url("../src/Images/aboutus.png") no-repeat center center scroll !important;
  }
  .aboutUsImage::after {
    background: url("../src/Images/aboutus.png") no-repeat center center !important;
  }
  .aboutUsImage p {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 653px) {
  .example::before {
    background: url("../src/Images/ladingpagebgmobile.jpg") no-repeat center
      center scroll;
    clip-path: inset(45px 0px 60px 0px);
  }

  .example {
    background: url("../src/Images/ladingpagebgmobile.jpg") no-repeat center
      center scroll !important;
  }
  .example::after {
    background: url("../src/Images/ladingpagebgmobile.jpg") no-repeat center
      center !important;
  }
  .landingPageHeight {
    height: 24vh;
  }
  .aboutUsImage p {
    font-size: 1.5rem;
  }

  .aboutUsImage {
    background: url("./Images/aboutusmob.png") no-repeat center center scroll !important;
  }
  .aboutUsImage::after {
    background: url("./Images/aboutusmob.png") no-repeat center center !important;
  }
  .aboutUsImage::before {
    background: url("./Images/aboutusmob.png") no-repeat center center scroll;
    clip-path: inset(35px 0px 52px 0px);
  }
}
@media only screen and (max-width: 720px) {
  .sasImageSize {
    height: 170px;
  }
  .example {
    background: url("../src/Images/ladingpagebgmobile.jpg") no-repeat center
      center scroll !important;
  }
  .example::after {
    background: url("../src/Images/ladingpagebgmobile.jpg") no-repeat center
      center !important;
  }

  .example::before {
    background: url("../src/Images/ladingpagebgmobile.jpg") no-repeat center
      center scroll;
    clip-path: inset(45px 0px 60px 0px);
  }

  .aboutUsImage::before {
    background: url("../src/Images/aboutusmob.png") no-repeat center center
      scroll;
    clip-path: inset(45px 0px 60px 0px);
  }
  .aboutUsImage {
    background: url("../src/Images/aboutusmob.png") no-repeat center center
      scroll !important;
  }
  .aboutUsImage::after {
    background: url("../src/Images/aboutusmob.png") no-repeat center center !important;
  }
  .aboutUsImage p {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 1370px) {
  .aboutUsImagePageHeight {
    height: 30vh;
  }
}
.navBarPosition {
  width: 100%;
  /* position: fixed; */
  z-index: 1000;
  box-shadow: 0 2px 3px rgb(0 0 0 / 18%) !important;
}
.global_select_list {
  background: #070c52 !important;

  box-sizing: border-box !important;
  border-radius: 8px !important;
  /* height: 40px !important; */
  z-index: 2000;
  font-style: normal !important;
  /* color: rgb(241, 241, 241) !important; */
  font-weight: 500;
  font-size: 14px;
}
.global_select_list .css-yk16xz-control {
  background-color: #1a3a6a;
  color: white;
}
.global_select_list .css-1uccc91-singleValue {
  color: #6c757d !important;
}
