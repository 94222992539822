#nav-section.shrink {
  border-color: #fff;
  box-shadow: 0px 0px 10px 0px #5d97c4;
}
.navbar-expand-sm {
  background: #fff;
  border-color: #3cb6ea;
  box-shadow: 0px 0px 15px 0px #3cb6ea;
}
.navbar-brand {
  float: left;
  height: 50px;
  margin-left: 0px;
  font-size: 18px;
  line-height: 20px;
  padding-bottom: 35px;
}
#header {
  background: none;
  padding: 40px 0 60px 0;
}
.icon-bar {
  background: #fff;
}
.dropdown-menu li a {
  display: block;
  clear: both;
  font-weight: 400;
  line-height: 1.728571;
  color: #000;
  white-space: nowrap;
}
.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 15px;
  background-color: rgba(41, 134, 226, 0.8);
  background-image: none;
  border: 1px solid #333;
  border-radius: 4px;
}

.navbar-nav {
  list-style-type: none;
  text-decoration: none;
  margin-top: 0px;
  padding: 0px;
  margin-right: 0px;
  color: #000;
}

.navbar-expand-sm .navbar-nav > li > a {
  font-weight: 400;
  color: #000;
  font-size: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 28px;
  padding-bottom: 28px;
}

.navbar li a:hover:not(.active) {
  background-color: rgba(41, 134, 226);
  color: #fff !important;
}

.nav > li > a:focus,
.nav > li > a:hover {
  text-decoration: none;
  background-color: rgba(41, 134, 226, 0.8);
}

.navbar-fixed-top {
  z-index: 1000;
}

.logo_Doc {
  margin-top: -10px;
}

@media only screen and (max-width: 768px) {
  #nav-section.shrink {
    padding: 10px 0;
  }

  .logo_Doc {
    margin-top: -20px;
  }
}
.linkwithnodropdown {
  color: #003b6f;
  text-decoration: none;
}
.linkwithnodropdown:hover {
  color: white;
}
