$background: #f6f7f8;
$grey-nuance-lighter: #eeeeee;
$grey-nuance-darker: #f5f5f5;
$fade-grey: #e8e8e8;

.fallback-container {
  background: #fff;
  height: 100vh;
  overflow: hidden;
  padding: 0 20px;
  border: 1px solid $fade-grey;
  position: relative;
}

.fallback {
  position: relative;
  padding: 20px 0;
  .header-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .circle-img {
      width: 50px;
      min-width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .header-content {
      margin-left: 20px;
      width: 100%;
      .content-shape {
        height: 8px;
        margin-bottom: 8px;
        &:first-child {
          width: 80%;
        }
        &:nth-child(2) {
          width: 60%;
        }
      }
    }
  }
  .image-placeholder {
    width: 100%;
    margin-top: 20px;
    height: 95px;
  }

  .footer-block {
    margin-top: 20px;
    .content-shape {
      height: 8px;
      margin-bottom: 8px;
      &:first-child {
        width: 32%;
      }
      &:nth-child(2) {
        width: 24%;
      }
    }
  }
}

.loads {
  animation: placeload 1s linear infinite forwards;
  -webkit-animation: placeload 1s linear infinite forwards;
  background: $background;
  background: $grey-nuance-lighter;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, $grey-nuance-lighter),
    color-stop(18%, $grey-nuance-darker),
    color-stop(33%, $grey-nuance-lighter)
  );
  background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background: linear-gradient(to right, $grey-nuance-lighter 8%, $grey-nuance-darker 18%, $grey-nuance-lighter 33%);
  background-size: 2000px 104px;
  -webkit-background-size: 2000px 104px;
  position: relative;
}

@-webkit-keyframes placeload {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@keyframes placeload {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
